import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";
import { Heading, Box, Flex, Text, Stack, Button } from "@chakra-ui/core";
import Container from "../components/Container";
import Banner from "../components/Banner";
import FormattedContent from "../components/FormattedContent";

export const AboutPageTemplate = ({
  title,
  content,
  heading,
  subheading,
  image,
  company,
  callToAction,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Box h={["300px", "280px", "250px", "200px"]}>
          <Container position="relative">
            <Banner.Container>
              <Banner.Image image={image} />
            </Banner.Container>
          </Container>
        </Box>
        <Box mb={[50, 50, 50, 75]}>
          <Heading as="h1" size="2xl" color="#4a93f7">
            {heading}
          </Heading>
          <Heading as="h2" size="lg" color="#5c626e" fontWeight="normal">
            {subheading}
          </Heading>
        </Box>
        <Flex flexDirection={["column", "column", "row"]}>
          <Box flexGrow={1} mb={[50, 50, 0]}>
            <FormattedContent>
              <PageContent content={content} />
            </FormattedContent>
          </Box>
          <Box w={["100%", "100%", "420px"]} pl={[0, 0, 80]} flexShrink={0}>
            <Button
              rightIcon="arrow-forward"
              variantColor="blue"
              as="a"
              href={callToAction.url}
              w="100%"
              mb={8}
            >
              {callToAction.text}
            </Button>

            <Box bg="#edf2f8" borderRadius={4} p={8} ml="auto">
              <Stack spacing={3}>
                <Heading size="md">{company.name}</Heading>
                <Box>
                  <Text fontSize="md">{company.street}</Text>
                  <Text fontSize="md">{company.zip}</Text>
                  <Text fontSize="md">{company.city}</Text>
                </Box>
                <Box>
                  <Text fontSize="md" fontWeight="600">
                    Telefoonnummer
                  </Text>
                  <Text fontSize="md">{company.phone}</Text>
                </Box>
                <Box>
                  <Text fontSize="md" fontWeight="600">
                    E-mailadres
                  </Text>
                  <Text fontSize="md">{company.email}</Text>
                </Box>
                <Box>
                  <Text fontSize="md" fontWeight="600">
                    KvK-nummer
                  </Text>
                  <Text fontSize="md">{company.commerceNumber}</Text>
                </Box>
                <Box>
                  <Text fontSize="md" fontWeight="600">
                    BTW-id
                  </Text>
                  <Text fontSize="md">{company.vatNumber}</Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  image: PropTypes.object,
  company: PropTypes.object,
  callToAction: PropTypes.object
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        heading={post.frontmatter.heading}
        subheading={post.frontmatter.subheading}
        image={post.frontmatter.image}
        company={post.frontmatter.company}
        callToAction={post.frontmatter.callToAction}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        company {
          name
          street
          zip
          city
          phone
          email
          commerceNumber
          vatNumber
        }
        callToAction {
          url
          text
        }
      }
    }
  }
`;
