import styled from "@emotion/styled";
import { Box } from "@chakra-ui/core";

const FormattedContent = styled(Box)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: 600;
    font-size: 1.2rem;
  }

  p,
  li {
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.2rem;
  }

  table {
    border-collapse: collapse;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  table caption {
    text-align: left;
    margin-bottom: 20px;
    font-weight: 600;
  }

  table,
  th,
  td {
    border: 1px solid #43526e;
    padding: 6px;
    text-align: center;
    vertical-align: center;
  }

  .table {
    overflow-x: auto;
  }
`;

export default FormattedContent;
