import PreviewCompatibleImage from "./../components/PreviewCompatibleImage";
import mask from "../img/mask.svg";
import styled from "@emotion/styled";
import React from "react";

export const Image = styled(PreviewCompatibleImage)`
  mask-image: url(${mask});
  mask-repeat: no-repeat;
  height: 100%;
  padding-top: 110px;
`;

export const BaseContainer = styled.div`
  position: absolute;
  position: absolute !important;
  top: -275px;
  right: -150px;
  width: 856px;
  z-index: 5;
  filter: drop-shadow(-15px 15px 0px #ebf4ff);
  height: 600px;
  overflow: hidden;
`;

const Container = ({ children }) => {
  return <BaseContainer className="banner">{children}</BaseContainer>;
};

export default {
  Container,
  Image,
};
